import { Outlet } from "react-router-dom";

import { styled } from "#theme";
import Navigation from "#components/Navigation";
import Footer from "#pages/Footer";
import { Theme } from "@radix-ui/themes";

const AppContainer = styled("div", {
  width: "100%",
});

function App() {
  return (
    <Theme>
      <AppContainer>
        <Navigation />
        <Outlet />
        <Footer />
      </AppContainer>
    </Theme>
  );
}

export default App;
