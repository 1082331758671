import * as RadixPopover from "@radix-ui/react-popover";
import React, { useCallback, useState } from "react";

export interface PopoverProps
  extends Pick<
    RadixPopover.PopoverContentProps,
    "align" | "alignOffset" | "side" | "sideOffset"
  > {
  trigger: React.ReactNode;
  children: React.ReactNode;
  contentRef: React.RefObject<HTMLElement>; 
}

function Popover({
  trigger,
  children,
  align,
  alignOffset,
  side,
  sideOffset,
  contentRef,
}: PopoverProps) {
  const [open, setOpen] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setOpen(true);
  }, []);

  const handleMouseLeave = useCallback((ev: React.MouseEvent<HTMLButtonElement>) => {
    console.log(contentRef.current, ev.target, ev.relatedTarget);
    
    if (!(ev.relatedTarget instanceof Node && contentRef.current?.contains(ev.relatedTarget))) {
      setOpen(false);
    }
  }, [contentRef]);

  return (
    <RadixPopover.Root open={open} onOpenChange={setOpen}>
      <RadixPopover.Trigger
        asChild
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {trigger}
      </RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          asChild
          align={align}
          alignOffset={alignOffset}
          side={side}
          sideOffset={sideOffset}
        >
          {children}
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
}

export default Popover;
