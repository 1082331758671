import SlideShow, { SlideShowProps } from "#components/SlideShow";
import { styled } from "#theme";
import image1 from "#assets/slideshow1.jpg";
import image2 from "#assets/slideshow2.jpg";
import image3 from "#assets/slideshow3.jpg";
import sponserSvg from "#assets/joola-logo.svg";
import { Avatar, Box, Flex, Grid, Heading } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";
import { Information } from "../../types";
import { getDisplayValueFromInformation } from "#pages/ProgramOverview/ProgramOverview";
import { academyStudents } from "#pages/Academy/Academy";
import { PersonIcon } from "@radix-ui/react-icons";

const ContentContainer = styled("div", {
  position: "relative",
});

const DashboardContainer = styled("div", {
  display: "grid",
});

const images: SlideShowProps["images"] = [
  {
    url: image1,
    alt: "image1",
  },
  {
    url: image2,
    alt: "image2",
  },
  {
    url: image3,
    alt: "image3",
  },
];

const StyledTitle = styled("p", {
  fontSize: "$11",
  textAlign: "center",
  fontFamily: "$title",
  marginBottom: "$4",
});

const StyledSponsorContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "$5",
  paddingTop: "$9",
  paddingBottom: "$9",
  borderTopWidth: "1px",
  borderTopStyle: "solid",
  borderTopColor: "$neutral400",
  borderBottomWidth: "1px",
  borderBottomStyle: "solid",
  borderBottomColor: "$neutral400",
});

// const StyledBox = styled(Flex, {
//   transform: "translate(-50%, -50%)",
//   top: "80%",
//   left: "50%",
//   position: "absolute",
// });

const annoucements: ({ priority: 0 | 1 | 2 } & Information)[] = [
  // {
  //   priority: 2,
  //   title: "Training Camp",
  //   type: "compound",
  //   value: [
  //     {
  //       type: "text",
  //       value:
  //         "We will have a training camp soon.  ",
  //     },
  //     {
  //       type: "internal-link",
  //       value: "/training/camp",
  //       displayValue: "Training camp",
  //     } as Information,
  //   ],
  // },
  {
    priority: 2,
    title: "Tournament",
    type: "compound",
    value: [
      {
        type: "text",
        value:
          "We will be hosting a tournment in Fort Collins from 10/19/2024 - 10/20/2024. For more information, go to: ",
      },
      {
        type: "internal-link",
        value: "/tournaments",
        displayValue: "Tournaments",
      } as Information,
    ],
  },
];

const StyledAnnouncementCard = styled(Box, {
});

const StyledAnnouncementGrid = styled(Box, {
  rowGap: "$3",
  columnGap: "$2",
  display: "grid !important",
  gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
});

const StyledAcademyStudentsContainer = styled(Box, {
  backgroundColor: "var(--gray-8)",
  borderTopWidth: "1px",
  borderTopStyle: "solid",
  borderTopColor: "$neutral400",
});

const StyledAnnouncementContainer = styled(Box, {
  borderTopWidth: "1px",
  borderTopStyle: "solid",
  borderTopColor: "$neutral400",
});

const StyledAcademyStudentsList = styled(Grid, {
  gridTemplateColumns: "repeat(auto-fill, minmax(12rem, 1fr)) !important",
  userSelect: "none",
});

function Dashboard() {
  // const navigate = useNavigate();

  return (
    <DashboardContainer>
      <ContentContainer>
        <SlideShow images={images} autoPlay />
        {/* <StyledBox gapX="2">
          <Button
            style={{ cursor: "pointer" }}
            variant="outline"
            color="cyan"
            size="4"
            onClick={() => navigate("/training/camp")}
          >
            Table Tennis Camp
          </Button>
          <Button
            style={{ cursor: "pointer" }}
            variant="outline"
            color="cyan"
            size="4"
            onClick={() => navigate("/tournaments")}
          >
            Join our tournament
          </Button>
        </StyledBox> */}
      </ContentContainer>
      <StyledAnnouncementContainer py="9" px="8">
        <StyledTitle css={{ textAlign: "left" }}>Announcements</StyledTitle>
        <StyledAnnouncementGrid>
          {
            // sort by priority
            annoucements.slice(0, 3).map((annoucement) => {
              return (
                <StyledAnnouncementCard key={annoucement.title} px="5" py="4">
                  <Heading mb="3">{annoucement.title}</Heading>
                  {getDisplayValueFromInformation(annoucement)}
                </StyledAnnouncementCard>
              );
            })
          }
        </StyledAnnouncementGrid>
      </StyledAnnouncementContainer>
      <StyledAcademyStudentsContainer py="9" px="8">
        <StyledTitle css={{ textAlign: "left" }}>Academy students</StyledTitle>
        <StyledAcademyStudentsList>
          {academyStudents.map((student) => (
            <Flex direction="column" align="center">
              <Avatar
                size="9"
                radius="full"
                fallback={<PersonIcon />}
                src={student.image}
              />
              <Heading mt="1" size="4" style={{ textAlign: "center" }}>
                {student.name}
              </Heading>
            </Flex>
          ))}
        </StyledAcademyStudentsList>
      </StyledAcademyStudentsContainer>
      <StyledSponsorContainer>
        <StyledTitle>Sponsored By:</StyledTitle>
        <div>
          <a href="https://joola.com" target="_blank" rel="noreferrer">
            <img src={sponserSvg} alt="sponser-logo" height="64px" />
          </a>
        </div>
      </StyledSponsorContainer>
    </DashboardContainer>
  );
}

export default Dashboard;
