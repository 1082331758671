import { createStitches } from "@stitches/react";

export const { styled, css } = createStitches({
  theme: {
    colors: {
      neutral100: "#fff",
      neutral150: "#f5f7f7",
      neutral200: "#e9eaec",
      neutral300: "#d3d5d9",
      neutral400: "#b8bcc2",
      neutral500: "#8b96a2",
      neutral600: "#556477",
      neutral700: "#334357",
      neutral800: "#232f3e",
      neutral900: "#06070a",

      primary100: "#f6f6fe",
      primary200: "#dfdffb",
      primary300: "#c3c3f4",
      primary400: "#9292e8",
      primary500: "#7471e0",
      primary600: "#5e5adb",
      primary700: "#3b36d3",
      primary800: "#27239f",
      primary900: "#181566",

      negative100: "#fdf6f7",
      negative200: "#f9d2d6",
      negative300: "#ef9098",
      negative400: "#e8636f",
      negative500: "#e23645",
      negative600: "#c91d2c",
      negative700: "#9c1722",
      negative800: "#6f1018",
      negative900: "#430a0f",

      positive100: "#f9fdf7",
      positive200: "#e7f7df",
      positive300: "#c8ecb6",
      positive400: "#91da6d",
      positive500: "#499325",
      positive600: "#3f7e20",
      positive700: "#2f5e18",
      positive800: "#214111",
      positive900: "#162d0b",

      warning100: "#fffcf5",
      warning200: "#fdf2d0",
      warning300: "#fadc80",
      warning400: "#f7c444",
      warning500: "#be8a08",
      warning600: "#966d2c",
      warning700: "#6e5020",
      warning800: "#533c18",
      warning900: "#3b2b03",

      info100: "#f4fbfd",
      info200: "#d3f0f8",
      info300: "#84d4eb",
      info400: "#3dbbe0",
      info500: "#1e95b8",
      info600: "#197d9b",
      info700: "#136077",
      info800: "#0e4758",
      info900: "#07232c",

      transparent: "rgba(0,0,0,0)",
      transparent100: "rgba(6,7,10,0.04)",
      transparent200: "rgba(6,7,10,0.1)",
      transparent300: "rgba(6,7,10,0.16)",
      transparent400: "rgba(6,7,10,0.5)",
    },
    space: {
      "0-5": "0.125rem",
      1: "0.25rem",
      2: "0.5rem",
      "2-5": "0.75rem",
      3: "1rem",
      "3-5": "1.25rem",
      4: "1.5rem",
      "4-5": "1.75rem",
      5: "2rem",
      6: "2.5rem",
      7: "3rem",
      "7-5": "3.5rem",
      8: "4rem",
      "8-5": "4.5rem",
      9: "5rem",
    },
    fontSizes: {
      1: "0.625rem",
      2: "0.75rem",
      3: "0.875rem",
      4: "1rem",
      5: "1.125rem",
      6: "1.25rem",
      "6-5": "1.375rem",
      7: "1.5rem",
      "7-5": "1.75rem",
      8: "2rem",
      9: "2.25rem",
      10: "2.5rem",
      11: "3rem",
      12: "3.75rem",
      13: "6.25rem",
    },
    fonts: {
      untitled: "Roboto, apple-system, sans-serif",
      mono: "Söhne Mono, menlo, monospace",
      title: "Bebas Neue",
      logo: "Secular One",
    },
    fontWeights: {},
    lineHeights: {
      1: 1,
      2: 1.12,
      3: 1.25,
      4: 1.3333,
      5: 1.4,
      6: 1.5,
      7: 1.6,
    },
    letterSpacings: {},
    sizes: {},
    borderWidths: {},
    borderStyles: {},
    radii: {
      0: "none",
      1: "0.125rem",
      2: "0.25rem",
      3: "0.375rem",
      4: "0.5rem",
      5: "0.75rem",
      6: "1rem",
      7: "1.25rem",
      8: "1.5rem",
      round: "50%",
    },
    shadows: {
      0: "none",
      1: "0 1px 2px 0 $colors$transparent300",
      2: "0 2px 4px 0 $colors$transparent300",
      3: "0 4px 8px 0 $colors$transparent300",
    },
    zIndices: {},
    transitions: {},
  },
});
