import { styled } from "#theme";
import pieke from "#assets/pieke.png";
import timothy from "#assets/timothy.png";
import PageContainer from "#components/PageContainer";
import { Box, Separator, Text } from "@radix-ui/themes";

const SubHeader = styled("h2", {
  marginBottom: "$0",
  fontFamily: "$title",
  fontSize: "$7-5",
  letterSpacing: 0.7,
});

const trainingStaffData = [
  {
    name: "Pieke Franssen",
    title: "National Head Coach & Director",
    qualifiations: [
      "European Champion",
      "Former Dutch Olympic and National Head Coach",
      "Former US National Team Coach",
      "USATT Hopes Program Head Coach (2018, 2019)",
    ],
    imageUrl: pieke,
  },
  {
    name: "Timothy Wang",
    title: "High Performance Coach",
    qualifiations: [
      "10x US National Champion",
      "2x Olympian (2012, 2016)",
      "US Open Champion",
      "North American Champion",
    ],
    imageUrl: timothy,
  },
];

const SectionsContainer = styled("div", {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(240px, 1fr))",
  rowGap: "$4",
  columnGap: "$4",
});

const StaffNameContainer = styled("h3", {
  fontFamily: "$title",
  fontSize: "$9",
  letterSpacing: 0.7,
  margin: 0,
});

const StaffIdentifierContainer = styled("div", {});

const QualificationList = styled("ul", {
  paddingLeft: "$3",
  marginTop: "$3",
});

const QualificationListItem = styled("li", {
  listStyleType: "disc",
  marginBottom: "$2",
});

const StyledStaffContainer = styled(Box, {
  padding: "1.5rem 2rem",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "$neutral200",
  borderRadius: "$4",
  boxShadow: "0 1px 2px 0 $colors$transparent300",
});

function TrainingStaff() {
  return (
    <PageContainer title="Training staff">
      <SectionsContainer>
        {trainingStaffData.map(({ name, title, qualifiations, imageUrl }) => {
          return (
            <StyledStaffContainer>
              <StaffIdentifierContainer>
                <StaffNameContainer>{name}</StaffNameContainer>
                <Text size="4">{title}</Text>
                <Separator mb="5" mt="3" style={{ width: "100%" }} />
                <img
                  alt={name}
                  src={imageUrl}
                  width="100%"
                  style={{
                    maxWidth: "400px",
                    borderRadius: "max(var(--radius-6), var(--radius-full))",
                  }}
                  height="auto"
                />
              </StaffIdentifierContainer>
              <Box mt="4">
                <SubHeader>Qualifications</SubHeader>
                <QualificationList>
                  {qualifiations.map((qualifiation) => {
                    return (
                      <QualificationListItem key={qualifiation}>
                        {qualifiation}
                      </QualificationListItem>
                    );
                  })}
                </QualificationList>
              </Box>
            </StyledStaffContainer>
          );
        })}
      </SectionsContainer>
    </PageContainer>
  );
}

export default TrainingStaff;
