import PageContainer from "#components/PageContainer";
import { styled } from "#theme";

const PointsList = styled("ul", {
  padding: "0 $4",
  li: {
    padding: "$2 0",
    fontSize: "$5",
    maxWidth: "780px",
    listStyleType: "disc",
  },
});

const points = [
  "MHTTA strives to be one of the top training centers in the US.",
  "We aim to create a group of (aspiring) high performance athletes that succeed at the national and international levels.",
  "Further we aim to build a table tennis community where people of all levels and ages can train and compete.",
];

function Vision() {
  return (
    <PageContainer title="Vision">
      <PointsList>
        {points.map((point) => (
          <li key={point}>{point}</li>
        ))}
      </PointsList>
    </PageContainer>
  );
}

export default Vision;
