import { styled } from "#theme";

const Container = styled("div", {
  padding: "$8 $8",

  "@media (max-width: 600px)": {
    padding: "$6 $4",
  },

  "a": {
    textDecoration: "underline",
    color: "$primary700",
  },
});

export const PageHeader = styled("h2", {
  marginBottom: "$7",
  fontFamily: "$title",
  fontSize: "$10",
  letterSpacing: 0.7,
});


function PageContainer({ children, title }: { children?: React.ReactNode, title: string }) {
  return (
    <Container>
      <PageHeader>{title}</PageHeader>
      {children}
    </Container>
  );
}

export default PageContainer;
