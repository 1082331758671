import { renderInformation } from "#pages/ProgramOverview/ProgramOverview";
import { styled } from "#theme";
import { Table, Box, Heading } from "@radix-ui/themes";
import { capitalize } from "lodash";
import { Information } from "../../types";

interface TournamentEvent {
  event: string;
  date: string;
  time: string;
  fee: string;
  prizes: { title: string; prize: string }[];
}

interface TournamentData {
  title: string;
  importantInformation: Information[];
  events: TournamentEvent[];
  eventsDisclaimer: { identifier: string; disclaimer: string }[];
  otherInformation: Information[];
}

const tournamentData: TournamentData = {
  title:
    "Table Tennis Connections/Mile High Table Tennis Academy Fall 2024 Open",
  importantInformation: [
    {
      title: "Star rating",
      type: "text",
      value: "Zero-star USATT Sanctioned",
    },
    {
      title: "Venue",
      type: "text",
      // g map?
      value:
        "Table Tennis Connections, Boys & Girls Club, Larimer County, 1608 Lancer Drive, Fort Collins, 80521 Colorado",
    },
    {
      title: "Dates",
      type: "date-range",
      value: [new Date(2024, 9, 19), new Date(2024, 9, 20)],
    },
    {
      title: "Entry Deadline",
      type: "compound",
      value: [
        {
          value: "Only online entry will be available through ",
        },
        {
          type: "external-link",
          value: "https://omnipong.com/T-tourney.asp?t=8&Region=4&y=&k=&e=0",
          displayValue: "https://omnipong.com",
        } as Information,
        {
          value:
            ", the latest by Monday, October 14th, 2024. Late entries may be accepted at the discretion of the tournament committee if the event is not full. Late fee of $15 will apply.",
        },
      ],
    },
    {
      title: "Equipment",
      type: "text",
      value:
        "12 Butterfly Tables, Nets, Barriers, and Joola Prime 40+ 3-star Balls. The floor is a rubberized sports floor.",
    },
    {
      title: "Tournament Information Contact",
      type: "compound",
      value: [
        {
          value:
            "Tournament Director Pieke Franssen Phone: (510) 607-1756 or by email: ",
        },
        {
          type: "email",
          value: "milehightta@gmail.com",
        },
      ],
    },
  ],
  events: [
    {
      event: "Open RR",
      date: "Sun",
      time: "2pm",
      fee: "$60",
      prizes: [
        {
          title: "1st",
          prize: "$600",
        },
        {
          title: "2nd",
          prize: "$300",
        },
        {
          title: "3nd/4th",
          prize: "$150",
        },
      ],
    },
    {
      event: "U2500 RR",
      date: "Sun",
      time: "9am",
      fee: "$45",
      prizes: [
        {
          title: "1st",
          prize: "$300",
        },
        {
          title: "2nd",
          prize: "$150",
        },
        {
          title: "3nd/4th",
          prize: "$75",
        },
      ],
    },
    {
      event: "U2300 RR",
      date: "Sun",
      time: "11:30am",
      fee: "$35",
      prizes: [
        {
          title: "1st",
          prize: "$200",
        },
        {
          title: "2nd",
          prize: "$100",
        },
        {
          title: "3nd/4th",
          prize: "$75",
        },
      ],
    },
    {
      event: "U2150 RR",
      date: "Sat",
      time: "2pm",
      fee: "$35",
      prizes: [
        {
          title: "1st",
          prize: "$150",
        },
        {
          title: "2nd",
          prize: "$75",
        },
        {
          title: "3nd/4th",
          prize: "Medals",
        },
      ],
    },
    {
      event: "U2000 RR",
      date: "Sat",
      time: "4:30pm",
      fee: "$35",
      prizes: [
        {
          title: "1st",
          prize: "$100",
        },
        {
          title: "2nd",
          prize: "$50",
        },
        {
          title: "3nd/4th",
          prize: "Medals",
        },
      ],
    },
    {
      event: "U1850 RR",
      date: "Sun",
      time: "9am",
      fee: "$35",
      prizes: [
        {
          title: "1st",
          prize: "$75*",
        },
        {
          title: "2nd",
          prize: "$40*",
        },
        {
          title: "3nd/4th",
          prize: "Medals",
        },
      ],
    },
    {
      event: "U1700 RR",
      date: "Sun",
      time: "11:30am",
      fee: "$35",
      prizes: [
        {
          title: "1st",
          prize: "$60*",
        },
        {
          title: "2nd",
          prize: "$30*",
        },
        {
          title: "3nd/4th",
          prize: "Medals",
        },
      ],
    },
    {
      event: "U1550 RR",
      date: "Sat",
      time: "9am",
      fee: "$35",
      prizes: [
        {
          title: "1st",
          prize: "$45*",
        },
        {
          title: "2nd",
          prize: "$25*",
        },
        {
          title: "3nd/4th",
          prize: "Medals",
        },
      ],
    },
    {
      event: "U1400 RR",
      date: "Sat",
      time: "2pm",
      fee: "$30",
      prizes: [
        {
          title: "1st",
          prize: "$30*",
        },
        {
          title: "2nd",
          prize: "$15*",
        },
        {
          title: "3nd/4th",
          prize: "Medals",
        },
      ],
    },
    {
      event: "U1200 RR",
      date: "Sat",
      time: "4:30pm",
      fee: "$30",
      prizes: [
        {
          title: "1st",
          prize: "$30*",
        },
        {
          title: "2nd",
          prize: "$15*",
        },
        {
          title: "3nd/4th",
          prize: "Medals",
        },
      ],
    },
    {
      event: "U1000 RR",
      date: "Sat",
      time: "9am",
      fee: "$30",
      prizes: [
        {
          title: "1st",
          prize: "$30*",
        },
        {
          title: "2nd",
          prize: "$15*",
        },
        {
          title: "3nd/4th",
          prize: "Medals",
        },
      ],
    },
  ],
  eventsDisclaimer: [
    {
      identifier: "*",
      disclaimer:
        "Prize money will be a gift certificate to be used for private lessons, group training, coaching during tournaments and/or training camps at Mile High Table Tennis Academy",
    },
    {
      identifier: "**",
      disclaimer: "Maximum rating for U3500 doubles is 2350",
    },
  ],
  otherInformation: [
    {
      title: "Rules",
      value:
        "Players must be members of the USATT or other ITTF affiliate associations. The Laws of Table Tennis as published by the ITTF, and all USATT regulations apply. The USATT dress code will be observed. All matches will be best of 5 games to 11 points, except for the final of the Open, which will be best of 7.",
    },
    {
      title: "Proof of membership",
      value:
        "Every participant must show proof of USATT membership for all events. Membership and renewals will be sold at the event. Tournament pass $50 / 1yr Basic $25, 1 yr Pro $75",
    },
    {
      title: "Policies",
      value:
        "The maximum number of players in any event is twenty (20). Events with insufficient entries may be canceled, and fees refunded. Unrated players will not be allowed to advance out of groups, except in the U300 and U550. The Tournament Director may assign an estimated rating to an unrated player. You can only enter one event at the same time.",
    },
    {
      title: "Payment",
      value:
        "Payments must be received prior to the start of the tournament, by Zelle (milehightta@gmail.com), by cash or by check (payable to Mile High Table Tennis Academy) and will not be accepted at the tournament. Payment for the registration must be made within three days or your entry, otherwise you may be removed to free up a slot for another player.",
    },
    {
      title: "Registration",
      value:
        "Players must register with tournament officials at least 30 minutes before their first match and be ready to play each match 10 minutes before the scheduled starting time.",
    },
    {
      title: "Withdrawal",
      value:
        "Any player who wishes to withdraw from the event must do so the latest by Monday, October 14th, 2024. There will be no refunds after this date.",
    },
    {
      title: "Ratings",
      value:
        "Your rating on September 29th will be used for event qualifying. Seedings will be based on the most current rating available.",
    },
    {
      title: "Tournament Umpire",
      value: "Pieke Franssen",
    },
  ],
};

const StyledInformationContainer = styled(Box, {
  display: "flex !important",
  flexDirection: "column",
  rowGap: "$2",
});

function Tournament() {
  return (
    <Box p="8">
      <Heading size="7" mb="7">
        {tournamentData.title}
      </Heading>
      <StyledInformationContainer mb="5">
        {tournamentData.importantInformation.map((info) =>
          renderInformation(info, "3", "600px")
        )}
      </StyledInformationContainer>

      <Heading mb="3">Events</Heading>
      <Table.Root>
        <Table.Header>
          <Table.Row>
            {Object.entries(tournamentData.events[0]).map(([key, value]) => {
              if (key === "prizes") {
                return (
                  <>
                    {(value as TournamentEvent["prizes"]).map((prize) => {
                      return (
                        <Table.ColumnHeaderCell key={prize.title}>
                          {capitalize(prize.title)}
                        </Table.ColumnHeaderCell>
                      );
                    })}
                  </>
                );
              }
              return (
                <Table.ColumnHeaderCell key={key}>
                  {capitalize(key)}
                </Table.ColumnHeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tournamentData.events.map((event) => (
            <Table.Row key={event.event}>
              <Table.Cell>{event.event}</Table.Cell>
              <Table.Cell>{event.date}</Table.Cell>
              <Table.Cell>{event.time}</Table.Cell>
              <Table.Cell>{event.fee}</Table.Cell>
              <>
                {event.prizes.map((prize) => {
                  return (
                    <Table.Cell key={prize.prize}>{prize.prize}</Table.Cell>
                  );
                })}
              </>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>

      <StyledInformationContainer mt="6">
        <Heading mb="3">Additional information</Heading>
        {tournamentData.otherInformation.map((info) =>
          renderInformation(info, "2", "100%", "800px")
        )}
      </StyledInformationContainer>
    </Box>
  );
}

export default Tournament;
