import { css, styled } from "#theme";
import { KeyboardArrowLeft, KeyboardArrowRight, Circle } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";

export interface SlideShowProps {
  images: { url: string; alt: string }[];
  autoPlay?: boolean;
  interval?: number;
  showArrows?: boolean;
}

const SlideShowContainer = styled("div", {
  position: "relative",
});

const SlideShowImage = styled("div", {
  height: "90vh",
  width: "100%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "auto",
  backgroundPosition: "50% 50%",
});

const SlideShowNavigateContainer = styled("div", {
  width: "100%",
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  // top: "bottom",
  bottom: 0,
  zIndex: "1",
  padding: "$3 0",
  "> *:not(:last-child)": {
    marginRight: "$2",
  },
});

const SlideShowArrowCss = css({
  zIndex: 1,
  position: "absolute",
  top: "50%",
  transforms: "translateY(-50%)",
  color: "rgba(255, 255, 255, 0.8)",
  cursor: "pointer",
  transition: "0.3s ease-in-out background-color",

  "&:hover": {
    backgroundColor: "rgba(184, 188, 194, 0.5)",
  },
});

const LeftArrow = styled("div", {
  left: "1rem",
});
const RightArrow = styled("div", {
  right: "1rem",
});

const activeCircleCss = css({
  cursor: "pointer",
  color: "$neutral100 !important",
});

const inactiveCircleCss = css({
  cursor: "pointer",
  color: "$neutral400 !important",
  transition: "0.3s ease-in-out color",
  "&:hover": {
    color: "$neutral200 !important",
  },
});

function SlideShow(props: SlideShowProps) {
  const { images, autoPlay, interval = 4000, showArrows = true } = props;
  const [page, setPage] = useState(0);

  useEffect(() => {
    let timer: NodeJS.Timer;

    function startAutoPLay() {
      timer = setInterval(() => {
        setPage((page) => (page + 1) % images.length);
      }, interval);
    }

    if (autoPlay) {
      startAutoPLay();
    }

    return () => {
      clearInterval(timer as any);
    };
  }, [autoPlay, images.length, interval]);

  const goToNextPage = useCallback(() => {
    setPage((page) => (page + 1 + images.length) % images.length);
  }, [images.length]);

  const goToPreviousPage = useCallback(() => {
    setPage((page) => (page - 1 + images.length) % images.length);
  }, [images.length]);

  const onChangePage = useCallback((page: number) => {
    setPage(page);
  }, []);

  const image = images[page];

  return (
    <SlideShowContainer>
      <SlideShowImage css={{ backgroundImage: `url(${image.url})` }} />
      {images.length > 1 && (
        <>
          {showArrows && <LeftArrow className={SlideShowArrowCss()} onClick={goToPreviousPage}>
            <KeyboardArrowLeft sx={{ fontSize: "48px" }} />
          </LeftArrow>}
          {showArrows && <RightArrow className={SlideShowArrowCss()} onClick={goToNextPage}>
            <KeyboardArrowRight sx={{ fontSize: "48px" }} />
          </RightArrow>}
          <SlideShowNavigateContainer>
            {images.map((_, i) => (
              <div
                onClick={() => onChangePage(i)}
                className={page === i ? activeCircleCss() : inactiveCircleCss()}
              >
                <Circle sx={{ fontSize: "12px" }} />
              </div>
            ))}
          </SlideShowNavigateContainer>
        </>
      )}
    </SlideShowContainer>
  );
}

export default SlideShow;
