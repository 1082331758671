import PageContainer from "#components/PageContainer";
import { styled } from "#theme";

const SectionsContainer = styled("div", {
  display: "grid",
  columnGap: "$5",
  gridTemplateColumns: "repeat(auto-fit, minmax(240px, 1fr))",
  rowGap: "$5",
});

const SectionContainer = styled("div", {});

const SectionHeader = styled("h3", {
  marginBottom: "$4",
  fontFamily: "$title",
  fontSize: "$7-5",
  letterSpacing: 0.7,
});

const schedules = [
  {
    title: "Intermediate and Advanced Level",
    description: "USATT, USATT League, DenverPong >500",
    time: [["Everyday", "1:30 - 4:30PM"]],
  },
  {
    title: "Starter level",
    description: "USATT, USATT League, DenverPong <500",
    time: [["Everyday", "9:00AM - 12:30PM"]],
  },
  {
    title: "Additional Group Training",
    description: "USATT, USATT League, DenverPong >500",
    time: [
      ["Tues/Thurs", "5:30 - 7:30 PM"],
      ["Sat", "2:00 - 4:00 PM"],
    ],
  },
];

const SectionItem = styled("li", {
  padding: "$1 0",
  lineHeight: 1.4,
  span: {
    display: "inline-block",
    marginRight: "$2",
  },
});

const SubsectionContainer = styled("div", {
  marginBottom: "$4",
});

const SubsectionTitle = styled("h3", {
  marginBottom: "$1",
});

const SubsectionHeader = styled("div", {
  marginBottom: "$2",
});

const FullCostContainer = styled("div", {
  marginBottom: "$3",
});

const MethodsContainer = styled("div", {
  display: "grid",
  rowGap: "$4",
});

function SummerCamp() {
  return (
    <PageContainer title="Summer camp">
      <SectionsContainer>
        <SectionContainer>
          <SectionHeader>Training schedule</SectionHeader>
          {schedules.map((schedule) => {
            return (
              <SubsectionContainer>
                <SubsectionHeader>
                  <SubsectionTitle>{schedule.title}</SubsectionTitle>
                  <p>{schedule.description}</p>
                </SubsectionHeader>

                <ul>
                  {schedule.time.map(([day, time]) => {
                    return (
                      <SectionItem>
                        <span>{day}</span>
                        <span>{time}</span>
                      </SectionItem>
                    );
                  })}
                </ul>
              </SubsectionContainer>
            );
          })}
        </SectionContainer>
        <SectionContainer>
          <SectionHeader>Cost</SectionHeader>
          <FullCostContainer>
            <SectionItem>
              <span>One Session:</span>
              <span>$70</span>
            </SectionItem>
            <SectionItem>
              <span>Full Week of Sessions:</span>
              <span>$315</span>
            </SectionItem>
          </FullCostContainer>

          <SubsectionContainer>
            <SubsectionHeader>
              <SubsectionTitle>Discounts:</SubsectionTitle>
            </SubsectionHeader>
            <ul>
              <SectionItem>
                <span><b>More than 1 camp:</b></span>
                <span>$15 discount per camp</span>
              </SectionItem>
              <SectionItem>
                <span><b>Full Academy Membership:</b></span>
                <span>$20 off per camp</span>
              </SectionItem>
              <SectionItem>
                <span><b>Sibling Discount:</b></span>
                <span>$10 per camper per camp</span>
              </SectionItem>
              <SectionItem>
                <span><b>Early Bird Discount:</b></span>
                <span>$30 discount if full payment made before May 1st</span>
              </SectionItem>
            </ul>
          </SubsectionContainer>
        </SectionContainer>

        <SectionContainer>
          <SectionHeader>Registration</SectionHeader>
          <SubsectionContainer>

            <MethodsContainer>
              <p>
                To register, email coach Pieke (
                <a href="mailto:milehightta@gmail.com">milehightta@gmail.com</a>
                )
              </p>
              <p>
                Payment methods: Cash, Check payable to “Mile High Table Tennis
                Academy”, or Zelle (
                <a href="mailto:milehightta@gmail.com">milehightta@gmail.com</a>
                ).
              </p>
              <p>
                Payments must be paid by the first day of participation to
                receive the discount.
              </p>
            </MethodsContainer>
          </SubsectionContainer>
        </SectionContainer>
      </SectionsContainer>
    </PageContainer>
  );
}

export default SummerCamp;
