import { PageHeader } from "#components/PageContainer";
import { styled } from "#theme";
import { PersonIcon } from "@radix-ui/react-icons";
import { Avatar, Box, Flex, Grid, Heading } from "@radix-ui/themes";
import Suhaan from "#assets/SuhaanLal.png";
import Vikram from "#assets/VikramRao.png";
import Varin from "#assets/VarinChandra.png";
import Cody from "#assets/CodyGao.png";
import Vivaan from "#assets/VivaanChandra.png";

interface Student {
  name: string;
  image?: string;
}

export const academyStudents: Student[] = [
  {
    name: "Cody Gao",
    image: Cody,
  },
  {
    name: "Suhaan Lal",
    image: Suhaan,
  },
  {
    name: "Varin Chandra",
    image: Varin,
  },
  {
    name: "Vikram Rao",
    image: Vikram,
  },
  {
    name: "Vivaan Chandra",
    image: Vivaan,
  },
];


const StyledSection = styled(Grid, {
  gridTemplateColumns: "repeat(auto-fill, minmax(14rem, 1fr)) !important",
  userSelect: "none",
});

function Academy() {
  return (
    <Box p="8">
      <PageHeader>Academy Students</PageHeader>
      <StyledSection gapY="6" gapX="7">
        {academyStudents.map((student) => (
          <Flex direction="column" align="center">
            <Avatar
              size="9"
              style={{ width: "240px", height: "240px" }}
              radius="full"
              fallback={<PersonIcon />}
              src={student.image}
            />
            <Heading mt="2" size="6" style={{ textAlign: "center" }}>
              {student.name}
            </Heading>
          </Flex>
        ))}
      </StyledSection>
    </Box>
  );
}

export default Academy;
